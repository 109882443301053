import { defineComponent, ref, nextTick, onActivated } from "vue";
import { useRouter } from "vue-router";
import Tabs from "@/components/Tabs/index.vue";
import Pagination from "@/components/Pagination/index.vue";
import Head from "@/components/Head/index.vue";
import List from "./components/List.vue";
export default defineComponent({
  name: "Activity",
  components: {
    Tabs: Tabs,
    Head: Head,
    Pagination: Pagination,
    List: List
  },
  setup: function setup() {
    var router = useRouter();
    var titleArr = ["可报名活动", "待开活动", "已开活动"];
    var table1 = ref();
    var table2 = ref();
    var table3 = ref();
    var tabsIndex = ref(0); // nextTick(()=>{
    //  (table1.value as typeof List).getTabledata();
    // })
    //tab切换

    function changeOther(val) {
      if (val == 0) {
        // if(tabsIndex.value!=val){
        nextTick(function () {
          table1.value.initData();
        }); // }

        tabsIndex.value = 0;
      } else if (val == 1) {
        // if(tabsIndex.value!=val){
        nextTick(function () {
          table2.value.initData();
        }); // }

        tabsIndex.value = 1;
      } else {
        // if(tabsIndex.value!=val){
        nextTick(function () {
          table3.value.initData();
        }); // }

        tabsIndex.value = 2;
      }
    } //刷新列表


    onActivated(function () {
      nextTick(function () {
        table1.value.getTabledata();
      });
    });
    return {
      router: router,
      titleArr: titleArr,
      changeOther: changeOther,
      //可报名活动
      table1: table1,
      //待开始活动
      table2: table2,
      //已开活动
      table3: table3,
      tabsIndex: tabsIndex
    };
  }
});